<template>
  <div class="rt-container">
    <div class="rt-col">

      <div class="sp-v-5 td-sp-v-3-1 md-sp-v-2" v-html="content"></div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  
  setup(props) {
    const config = useRuntimeConfig()
  
    let content = toRaw(props.data).text

    content = content.replace('<h2>', '<h2 class="text-field__h2 font-h2">')
    content = content.replaceAll('<p>', '<p class="text-field__content font-t-m">')
    content = content.replaceAll('<li>', '<li class="text-field__content font-t-m">')

    function fixImageUrl(content) {
      let src = /src=\"[A-Za-z0-9_.-\/]+\"/g
      const urls = content.match(src)

      for(const url of urls) {
        const urlParts = url.split('="')
        urlParts[1] = config.public.strapiUrl + urlParts[1]
        content = content.replace(url, urlParts.join('="'))
      }

      return content
    }

    content = fixImageUrl(content)
  
    return {
      content
    }
  }
}

</script>

<style lang="stylus">
.text-field__content
  color #343B48

ol, ul
  padding-left 20px

.text-field__h2
  color #273A64

</style>
